import React from "react";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { useTheme } from "@mui/material/styles";
import { SvgIconProps } from "@mui/material/SvgIcon";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { INotificationDialogProps, NotificationVariant } from "./types";

const NotificationDialog: React.FC<INotificationDialogProps> = ({
  open,
  variant = "success",
  title,
  content,
  agreeText,
  disagreeText,
  showIcon = true,
  onAgree,
  onDisagree,
  dialogProps,
  children,
  agreeButtonProps,
  disagreeButtonProps,
  gridContainerProps,
  borderLineColor,
  agreeEl,
}): JSX.Element => {
  const theme = useTheme();
  const colors: {
    [key in NotificationVariant]: string;
  } = {
    success: theme.palette.success.dark,
    warning: theme.palette.warning.light,
    error: theme.palette.error.dark,
    info: theme.palette.info.dark,
  };
  const icons: {
    [key in NotificationVariant]: ((props: SvgIconProps) => JSX.Element) | null;
  } = {
    success: CheckCircleRoundedIcon,
    warning: WarningRoundedIcon,
    error: CancelRoundedIcon,
    info: null,
  };
  const VariantIcon = icons[variant];
  return (
    <Dialog
      {...dialogProps}
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          m: 2,
        },
      }}
    >
      <Grid
        {...(gridContainerProps || {})}
        container
        direction="row"
        wrap="nowrap"
        sx={{
          ...(gridContainerProps?.sx || {}),
          p: 2,
          pl: 0,
        }}
      >
        <Grid item xs="auto">
          <Box
            sx={{
              borderLeft: 4,
              borderLeftStyle: "solid",
              display: "flex",
              height: "100%",
              alignItems: "center",
              mr: 2,
              borderColor: borderLineColor || colors[variant],
            }}
          />
        </Grid>
        <Grid container item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: "column",
              flexGrow: 1,
              p: 2,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                "& .MuiDialogTitle-root, & .MuiDialogContent-root": {
                  padding: 0,
                },
                "& .MuiDialogContentText-root": {
                  marginBottom: 0,
                },
              }}
            >
              <Box mx={2}>
                {VariantIcon !== null && showIcon && (
                  <VariantIcon fontSize="large" htmlColor={colors[variant]} />
                )}
              </Box>
              <Box>
                {title && (
                  <DialogTitle>
                    <Typography variant="h5">{title}</Typography>
                  </DialogTitle>
                )}
                {(content || children) && (
                  <DialogContent>
                    {content && (
                      <DialogContentText variant="body1">
                        {content}
                      </DialogContentText>
                    )}
                    {children}
                  </DialogContent>
                )}
              </Box>
            </Box>
            <Box width="100%">
              <DialogActions>
                {disagreeText && (
                  <Button
                    size="small"
                    onClick={onDisagree}
                    variant="outlined"
                    color="warning"
                    {...disagreeButtonProps}
                  >
                    {disagreeText}
                  </Button>
                )}
                {agreeEl ||
                  (agreeText && (
                    <Button
                      size="small"
                      onClick={onAgree}
                      variant="outlined"
                      color="primary"
                      {...agreeButtonProps}
                    >
                      {agreeText}
                    </Button>
                  ))}
              </DialogActions>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NotificationDialog;
